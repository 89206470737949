<template>
    <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
        <div class="clinic-list" style="min-height: 100vh;" ref="listView">
            <div class="booking-banner">
                <h2 class="toolbar-title">診所列表</h2>
            </div>
            <div class="booking-search-wrapper">
                <div class="booking-search">
                    <span class="icon-search"></span>
                    <input type="text" class="keyword" placeholder="请输入诊所名称" v-model="keyword" @keyup="onKeyEnter">
                    <div class="btn-search" @click="doSearch">搜索</div>
                </div>
                <router-link to="/doctor/list" class="link-item">醫師列表</router-link>
            </div>
            <div class="clinic-item" v-for="clinic in dataList" :key="clinic.clinicid">
                <div class="row">
                    <router-link :to="'/hospital/detail/'+clinic.clinicid" style="flex: 1;">
                        <h2 class="clinic-name" v-text="clinic.clinicname"></h2>
                    </router-link>
                    <!-- <span class="distance" v-text="`距離${clinic.distance.toFixed(2)}km`"></span> -->
                </div>
                <p v-if="clinic.workdays && '1,2,3,4,5,6,7'.indexOf(clinic.workdays) == -1" class="work-time" v-text="`${clinic.workdays.split(',').map(d=>weeks[d-1]).join(',')} ${clinic.workstarttime.substring(0, 5)}-${clinic.workendtime.substring(0, 5)}${clinic.worktimeremark && `(${clinic.worktimeremark})`}`"></p>
                <p v-if="clinic.workdays && '1,2,3,4,5,6,7'.indexOf(clinic.workdays) != -1" class="work-time" v-text="`${clinic.workdays.split(',').map(d=>weeks[d-1]).shift()}至${clinic.workdays.split(',').map(d=>weeks[d-1]).pop()} ${clinic.workstarttime.substring(0, 5)}-${clinic.workendtime.substring(0, 5)}${clinic.worktimeremark && `(${clinic.worktimeremark})`}`"></p>
                <div class="row bottom">
                    <div class="address-container">
                        <span class="icon-address"></span>
                        <p class="address" v-text="clinic.address"></p>
                    </div>
                    <div class="btn-col tel">
                        <span class="icon"></span>
                        <a class="txt" :href="`tel:${clinic.contacttel}`">呼叫诊所</a>
                    </div>
                    <div class="btn-col booking">
                        <span class="icon"></span>
                        <router-link class="txt" :to="`/hospital/detail/${clinic.clinicid}`">预约醫師</router-link>
                    </div>
                </div>
            </div>
            <div class="load-more" v-if="isLoadMore">
				<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
				<span class="text">正在加载...</span>
			</div>
			<div class="load-finished" v-if="isLoadFinished">已全部加載</div>
		</div>
		<loading v-if="loading" class="loading"/>
    </van-pull-refresh>
</template>

<script>
    import clinic_list from '@/views/mixins/clinic_list'
    import Loading from '@/layouts/Loading.vue'
	export default {
        mixins: [clinic_list],
        components: {
            Loading,
        },
        data(){
            return {
                pageNo: 1,
                pageSize: 10,
                arealevel1id: null,
                arealevel2id: null,
                loading: true,
                isRefresh: false,
                isLoadMore: false,
                isLoadFinished: false,
                keyword: '',
                categoryid: null,
                dataList: [],
                maxPage: 0,
                weeks: [
                    '週一',
                    '週二',
                    '週三',
                    '週四',
                    '週五',
                    '週六',
                    '週日',
                ],
            }
        },
        mounted: function(){
            var hash = this.$route.hash
            hash = hash.replace('#', '')
            var list = hash.split('&')
            var key = list[0].split('=')[0]
            var value = list[0].split('=')[1] / 1
            if(key == 'page') {
                if(! isNaN(value)) {
                    this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                }
            }
            this.keyword = this.$route.query.keyword || undefined
            this.categoryid = this.$route.query.CatID || undefined
            this.onRefresh()
        },
        methods: {
            
            onKeyEnter: function(key){
                if(key.key === 'Enter') {
                    this.doSearch()
                }
            },
            doSearch() {
                this.pageNo = 1
                this.onRefresh()
            },
            onRefresh() {
				this.pageNo = 1
				this.loading = true
				this.isLoadMore = false
				this.isLoadFinished = false
				this.loadData({
                    "arealevel1id": this.arealevel1id || undefined,
                    "arealevel2id": this.arealevel2id || undefined,
                    "illnesscategoryid": this.categoryid || undefined,
                    "keyword": this.keyword || undefined,
                    "pageno": this.pageNo, 
                    "pagesize": this.pageSize,
                    "sortdesc": undefined,
                    "sorttypeid": undefined,
                }, (data, error)=>{
					this.loading = false
					this.isRefresh = false
					this.dataList = (data && data.list) || []
					var total = (data && data.total) || 0
					if(total > 0) {
						this.maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
						if(this.pageNo >= this.maxPage) {
							this.isLoadFinished = true
						}
					} else {
						if(!error) {
							this.isLoadFinished = true
						}
					}
                })
            },
			handleScroll (event) {
				if(this.$refs.listView.clientHeight < window.innerHeight) return
				if((window.innerHeight + window.pageYOffset) > this.$refs.listView.clientHeight) {
					// 加载更多
					if(!this.loading && !this.isLoadMore && this.pageNo < this.maxPage) {
						this.isLoadMore = true
						this.loading = true
						this.loadData({
                            "arealevel1id": this.arealevel1id || undefined,
                            "arealevel2id": this.arealevel2id || undefined,
                            "illnesscategoryid": this.categoryid || undefined,
                            "keyword": this.keyword || undefined,
                            "pageno": this.pageNo+1, 
                            "pagesize": this.pageSize,
                            "sortdesc": undefined,
                            "sorttypeid": undefined,
                        }, (data, error)=>{
							this.loading = false
							this.isLoadMore = false
							this.dataList = this.dataList.concat((data && data.list) || [])
							if(!error) {
								this.pageNo = this.pageNo + 1
								var total = (data && data.total) || 0
								if(total > 0) {
									var maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
									if(this.pageNo >= maxPage) {
										this.isLoadFinished = true
									}
								}
							}
						})
					}
				}
			},
		},
		created () {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
        },
    }
</script>

<style lang="scss" scoped>
    .clinic-item {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding-top: 40px;
        margin: 20px 24px 0px 24px;
    }

    .clinic-item .row {
        display: flex;
        flex-direction: row;
    }

    .clinic-item .clinic-name {
        line-height: 38px;
        padding-left: 47px;
        margin-left: 24px;
        flex: 1;
        text-align: left;
        color: #1F1F1F;
        font-weight: bold;
        font-size: 34px;
        position: relative;
    }

    .clinic-item .clinic-name::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 38px;
        height: 38px;
        border-radius: 8px;
        background-color: #FF6A21;
        padding: 4px;
        background-image: url(../../../assets/imgs/mobile/clinic/icon_dianpu@2x.png);
        background-repeat: no-repeat;
        background-size: 30px 30px;
        background-position-x: 4px;
        background-position-y: 4px;
        box-sizing: border-box;
    }

    .clinic-item .distance {
        margin-right: 24px;
        line-height: 38px;
        font-weight: 400;
        color: #505050;
        font-size: 24px;
        margin-left: 12px;
    }

    .clinic-item .work-time {
        margin-top: 23px;
        padding-bottom: 24px;
        line-height: 36px;
        border-bottom: 1px solid #DDDDDD;
        padding-left: 72px;
        color: #1F1F1F;
        font-size: 28px;
        font-weight: 400;
    }

    .clinic-item .address-container {
        display: flex;
        flex-direction: row;
        flex: 1;
        margin-top: 13px;
    }
    
    .clinic-item .address-container .address {
        color: #505050;
        font-size: 26px;
        line-height: 32px;
        font-weight: 400;
    }

    .clinic-item .address-container .icon-address {
        width: 20px;
        height: 24px;
        margin: 0px 18px 0px 33px;
        background-image: url(../../../assets/imgs/mobile/clinic/icon_diwei@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .clinic-item .btn-col {
        display: flex;
        flex-direction: column; 
        min-height: 88px;
        width: 156px;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #DDDDDD;
        font-size: 24px;
        font-weight: 400;
    }

    .clinic-item .btn-col.tel .icon {
        width: 28px;
        height: 28px;
        background-image: url(../../../assets/imgs/mobile/clinic/icon_hujiao@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .clinic-item .btn-col.booking .icon {
        width: 32px;
        height: 32px;
        background-image: url(../../../assets/imgs/mobile/clinic/img_yis@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .clinic-item .btn-col.tel .txt {
        color: #FF6A21;
    }

    .clinic-item .btn-col.booking .txt {
        color: #6379FF;
    }

	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}

	// loadmore
	.load-more {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.load-more > .text {
		margin-left: 24px;
	}

	.lds-default {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-default div {
		position: absolute;
		width: 6px;
		height: 6px;
		background: #36C4D0;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
	}
	.lds-default div:nth-child(1) {
		animation-delay: 0s;
		top: 37px;
		left: 66px;
	}
	.lds-default div:nth-child(2) {
		animation-delay: -0.1s;
		top: 22px;
		left: 62px;
	}
	.lds-default div:nth-child(3) {
		animation-delay: -0.2s;
		top: 11px;
		left: 52px;
	}
	.lds-default div:nth-child(4) {
		animation-delay: -0.3s;
		top: 7px;
		left: 37px;
	}
	.lds-default div:nth-child(5) {
		animation-delay: -0.4s;
		top: 11px;
		left: 22px;
	}
	.lds-default div:nth-child(6) {
		animation-delay: -0.5s;
		top: 22px;
		left: 11px;
	}
	.lds-default div:nth-child(7) {
		animation-delay: -0.6s;
		top: 37px;
		left: 7px;
	}
	.lds-default div:nth-child(8) {
		animation-delay: -0.7s;
		top: 52px;
		left: 11px;
	}
	.lds-default div:nth-child(9) {
		animation-delay: -0.8s;
		top: 62px;
		left: 22px;
	}
	.lds-default div:nth-child(10) {
		animation-delay: -0.9s;
		top: 66px;
		left: 37px;
	}
	.lds-default div:nth-child(11) {
		animation-delay: -1s;
		top: 62px;
		left: 52px;
	}
	.lds-default div:nth-child(12) {
		animation-delay: -1.1s;
		top: 52px;
		left: 62px;
	}
	@keyframes lds-default {
		0%, 20%, 80%, 100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.5);
		}
	}

    .booking-banner {
        display: block;
        margin: 11px 24px 0px 24px;
        background-image: url(../../../assets/imgs/mobile/doctor-list/img_banneryy@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 194px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .booking-banner > .toolbar-title {
        display: inline-block;
        width: 334px;
        height: 91px;
        line-height: 91px;
        text-align: center;
        background-color: rgba(5, 27, 48, .4);
        font-size: 54px;
        font-weight: bold;
        color: #fff;
    }
    
    .booking-search-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .booking-search {
        display: flex;
        flex: 1;
        flex-direction: row;
        margin: 16px 24px;
        background-color: #fff;
        height: 56px;
        border-radius: 16px;
    }

    .booking-search > .icon-search {
        display: inline-block;
        width: 21px;
        height: 21px;
        margin: 18px 15px 17px 19px;
        background-image: url(../../../assets/imgs/mobile/doctor-list/icon_search@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .booking-search > input.keyword {
        height: 56px;
        flex: 1;
        font-size: 28px;
        font-weight: 400;
        color: #231F20;
    }

    .booking-search > input.keyword::placeholder {
        font-size: 24px;
        color: #969696;
        font-weight: 400;
    }

    .booking-search > .btn-search {
        opacity: 1;
        width: 114px;
        height: 56px;
        line-height: 56px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        font-size: 32px;
        background-color: #36C4D0;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
    }

    .booking-search > .btn-search::selection {
        color: #fff;
    }

    .booking-search > .btn-search:active {
        opacity: 9;
    }
    .booking-search-wrapper .link-item {
        height: 56px;
        line-height: 56px;
        font-size: 30px;
        font-weight: 500;
        margin-right: 24px;
        text-decoration: underline;
    }

    .loading {
        padding-top: 300px;
    }
</style>
